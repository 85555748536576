<template>
    <Table :cols="cols"
           :data="castBookings"
           :sortable="true"
           class="table-quotes mt-5"
           @sort="handleSort"
           v-on="$listeners"
    >
        <template v-slot:route_type="slotProps">
            <svg v-if="slotProps.row.route_type === transportTypes.TRUCK" xmlns="http://www.w3.org/2000/svg" width="20"
                 height="24.465"
                 viewBox="0 0 20 24.465">
                <path d="M91.532,446.606H83.386l.758,1.674h6.631Z" transform="translate(-77.459 -428.909)"/>
                <path
                    d="M74.005,371.085a.376.376,0,0,0-.311-.35l-.39-.078,0-.2-1.631-.157c-.171-1.86-.476-3.407-1.02-3.407,0,0-.007-1.8-1.1-1.8H58.629c-1.09,0-1.1,1.8-1.1,1.8-.544,0-.849,1.548-1.02,3.407l-1.631.157,0,.2-.39.078a.376.376,0,0,0-.311.35l-.088,2.761a.447.447,0,0,0,.33.437l.489.145,0,.188,1.388.657c-.006.807,0,1.334,0,1.334l-.295.276v1.8a1.1,1.1,0,0,0,1.062,1.07l.911.062a.622.622,0,0,1,.4.178l.905.909H68.9l.905-.909a.623.623,0,0,1,.4-.178l.911-.062a1.1,1.1,0,0,0,1.062-1.07v-1.8l-.295-.276s.007-.527,0-1.334l1.388-.657,0-.188.489-.145a.448.448,0,0,0,.331-.437Zm-18.859,3.372,0-.081.02-.009a.4.4,0,0,0,.244-.338l.162-2.582a.58.58,0,0,0-.246-.506l-.033-.024.006-.146,1.173-.036c-.115,1.45-.156,3.027-.169,4.181Zm13.042,5.269H60c-.609,0-1.648-2.568-1.648-2.568H69.836S68.8,379.726,68.188,379.726Zm1.465-4.288H58.533c-.763,0-.986-2.351-.986-3.874a7.778,7.778,0,0,1,.141-1.954l6.4-.146,6.4.146a7.778,7.778,0,0,1,.141,1.954C70.638,373.087,70.416,375.438,69.653,375.438Zm2.227-.522c-.013-1.154-.054-2.73-.169-4.181l1.173.036.006.146-.033.024a.58.58,0,0,0-.246.506l.162,2.582a.4.4,0,0,0,.244.338l.02.009,0,.081Z"
                    transform="translate(-54.093 -365.089)"/>
                <path
                    d="M76.2,439.262l-1.662,1.529-1.35,4.807-3.094-.017L67,445.6l-1.35-4.807-1.662-1.529-1.425-.347-.48.676v6.242l.691.64v1.284a.549.549,0,0,0,.5.585H64.5a.549.549,0,0,0,.5-.585v-1.031H75.191v1.031a.549.549,0,0,0,.5.585h1.224a.549.549,0,0,0,.5-.585v-1.284l.691-.64v-6.242l-.48-.676Zm-13.564,2.744-.046-1.393,2.143.12.459,1.983-2.06-.123A.574.574,0,0,1,62.636,442.007Zm1.082,2.808a1.086,1.086,0,0,1-.907-1.141v-.419l2.71.126L65.882,445Zm13.659-1.141a1.086,1.086,0,0,1-.907,1.141L74.305,445l.362-1.617,2.71-.126Zm.175-1.667a.574.574,0,0,1-.5.588l-2.06.123.459-1.983,2.143-.12Z"
                    transform="translate(-60.094 -423.878)"/>
                <path d="M87.03,457.664l.352.924H93.01l.352-.924H87.03Z"
                      transform="translate(-80.196 -437.961)"/>
            </svg>
            <svg v-else-if="slotProps.row.route_type === transportTypes.AIR" xmlns="http://www.w3.org/2000/svg" width="24.449"
                 height="24.645"
                 viewBox="0 0 24.449 24.645">
                <path class="a"
                      d="M288.694,290.942a7.164,7.164,0,0,0,1.608-3.764,1.406,1.406,0,0,0-.19-.769.62.62,0,0,0-.111-.143.656.656,0,0,0-.13-.1,1.354,1.354,0,0,0-.773-.2,7.026,7.026,0,0,0-3.733,1.62,32.256,32.256,0,0,0-3.676,3.571c-.256.271-.524.555-.808.854l-2.912-.88.008-.008a.66.66,0,0,0,0-.927l-.526-.531a.652.652,0,0,0-.463-.194.639.639,0,0,0-.461.193l-.787.793-.416-.125a.662.662,0,0,0-.052-.868l-.529-.533a.652.652,0,0,0-.463-.191.638.638,0,0,0-.459.192l-.722.728-2.962-.894a.728.728,0,0,0-.211-.03.78.78,0,0,0-.548.223l-1.041,1.05a.62.62,0,0,0-.183.433.6.6,0,0,0,.317.539l6.227,3.507a1.116,1.116,0,0,1,.152.116l1.775,1.789c-1.66,1.763-3.14,3.472-4.293,4.957-.107.138-.209.273-.31.406l-4.817-.441a.743.743,0,0,0-.083-.005.89.89,0,0,0-.6.249l-.485.489a.61.61,0,0,0-.186.433.594.594,0,0,0,.351.549l3.389,1.623a.816.816,0,0,1,.13.094l.281.281a2.082,2.082,0,0,0-.135.657.866.866,0,0,0,.236.629l.012.011,0,0a.856.856,0,0,0,.623.236,2.077,2.077,0,0,0,.647-.136l.286.287a.8.8,0,0,1,.088.128l1.607,3.409a.6.6,0,0,0,.539.358h0a.6.6,0,0,0,.429-.182l.5-.5a.9.9,0,0,0,.237-.6c0-.028,0-.049,0-.062l-.439-4.874c.132-.1.266-.2.4-.311,1.475-1.164,3.171-2.657,4.917-4.328l1.777,1.793a.959.959,0,0,1,.116.153l3.473,6.266a.6.6,0,0,0,.969.144l1.048-1.057a.806.806,0,0,0,.215-.551.766.766,0,0,0-.028-.206l-.888-2.986.722-.729a.661.661,0,0,0,0-.928l-.529-.534a.652.652,0,0,0-.862-.054l-.124-.418.788-.794a.663.663,0,0,0,.191-.465.651.651,0,0,0-.191-.464l-.526-.531a.656.656,0,0,0-.464-.194.645.645,0,0,0-.461.193l-.006.006-.873-2.936q.452-.435.861-.825a32.1,32.1,0,0,0,3.53-3.693Z"
                      transform="translate(-265.852 -285.966)"/>
            </svg>
            <svg v-else-if="slotProps.row.route_type === transportTypes.SEA" xmlns="http://www.w3.org/2000/svg" width="35"
                 height="16.966" viewBox="0 0 35 16.966">
                <g>
                    <path
                        d="M3.238,16.964.081,12.231a.99.99,0,0,1,.52-1.3.981.981,0,0,1,.389-.081h7.77l1.183,1.183h17.17l3.747-2.169H35l-3.551,5.129s.986.2.986,1.974Zm19.728-5.917V8.284h5.129v1.974l-1.185.792ZM17.048,8.284h5.129v2.762H17.048Zm-6.117,0h5.131v2.762H10.932ZM2.053,9.861V1.777H4.225V1.185L7.183,0V1.775H8.366V2.958H3.04v.988H8.366V5.129H7.183V9.863ZM22.964,4.535h5.129V7.3H22.964Zm-5.917,0h5.129V7.3H17.046Zm-6.117,0h5.132V7.3H10.93Z"
                        transform="translate(0 0)"/>
                </g>
            </svg>
            <svg v-else-if="slotProps.row.route_type === transportTypes.PARCEL"
                 xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                  viewBox="0 0 24.4 24"
                  width="24.4"
                  height="24"
                  xml:space="preserve">
                <g>
                    <path d="M0,16.7c0,1,0.5,1.8,1.4,2.2l9.9,4.9V12.6L0,6.8V16.7z"/>
                    <path d="M13.1,12.6V24l10-5c0.9-0.4,1.4-1.3,1.4-2.2v-10L13.1,12.6z"/>
                    <path d="M16.6,1.9l-3.3-1.7c-0.7-0.4-1.6-0.4-2.3,0L0.8,5.4l4.5,2.3L16.6,1.9z"/>
                    <polygon points="18.6,2.9 7.2,8.6 12.2,11.1 23.6,5.4 	"/>
                </g>
            </svg>
        </template>


        <template v-slot:action>
            <Dropdown
                btn-class-name="btn btn-light btn-sm d-flex align-items-center p-2"
                menu-class-name="dropdown-menu-right">
                <template v-slot:dropdown-btn-label>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" fill="currentColor"
                         class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                        <path fill-rule="evenodd"
                              d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                    </svg>
                </template>
                <template v-slot:dropdown-menu>
                    <button class="dropdown-item text-right">Row Action 1</button>
                    <button class="dropdown-item text-right">Row Action 2</button>
                    <button class="dropdown-item text-right">Row Action 3</button>
                    <button class="dropdown-item text-right">Row Action 4</button>
                </template>
            </Dropdown>
        </template>
    </Table>
</template>

<script>
import Table from "@/components/table/Table";
import Dropdown from "@/components/Dropdown";
import moment from "moment";
import {transportTypes} from "../../config/checkout";

export default {
    name: "TableQuotes",
    components: {Dropdown, Table},
    data: () => ({
        cols: {
            id: {label: 'Id', sortable: true, active: false, order: 'desc'},
            loading_from: {label: 'From', sortable: true, active: false},
            delivery_to: {label: 'To', sortable: true, active: false},
            collection_date: {label: 'Data', sortable: true, active: false},
            price: {label: 'Price',},
            route_type: {label: 'Type', sortable: true, active: false},
        },
        sort: {
            orderBy: 'id',
            order: 'desc'
        },
        transportTypes
    }),

    created() {
        for (const [key, col] of Object.entries(this.cols)) {
            if (col.sortable && key === this.sort.orderBy) {
                this.cols[key]['active'] = true;
                this.cols[key]['order'] = this.sort.order;
            }
        }
    },

    props: {
        bookings: Object,
    },
    watch: {
        sort: {
            handler(sort) {
                for (const [key, col] of Object.entries(this.cols)) {
                    if (col.sortable && key === sort.orderBy) {
                        this.cols[sort.orderBy]['active'] = true;
                        this.cols[sort.orderBy]['order'] = sort.order;
                    } else {
                        this.cols[key]['active'] = false;
                        this.cols[key]['order'] = 'desc';
                    }
                }
            },
            deep: true
        }
    },
    computed: {
        castBookings() {
            const output = [];
            if (this.bookings.data) {
                for (const [, booking] of Object.entries(this.bookings.data)) {
                    output.push({
                        id: `#${booking.short_reference_key}`,
                        collection_date: moment(booking.collection_date).format('DD-MM-YYYY'),
                        price: booking.price_converted.formatted,
                        loading_from: booking.loading_from?.address?.label ?? '',
                        delivery_to: booking.delivery_to?.address?.label ?? '',
                        route_type: booking.route_type,
                    })
                }
            }
            return output;

        }
    },
    methods: {
        handleSort({order, orderBy}) {
            this.sort.order = order;
            this.sort.orderBy = orderBy;
        }
    }
}
</script>

<style lang="scss">
.table-quotes {

    .table {
        @include media-breakpoint-down('md') {
            margin-bottom: 2rem;
        }
    }

    .table-header {
        @include media-breakpoint-down('md') {
            display: none;
        }

        &-column {
            &-type {
                text-align: center;
            }
        }
    }

    .table-row {
        @include media-breakpoint-down('md') {
            display: flex;
            flex-wrap: wrap;
            flex: 0 0 50%;
            max-width: 50%;
            padding: 15px $card-spacer-x;
            position: relative;
        }
        @include media-breakpoint-down('xs') {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }

    .table-body {
        @include media-breakpoint-down('md') {
            display: flex;
            flex-wrap: wrap;
            margin: 0 -$card-spacer-x;
        }

        &-column {
            vertical-align: middle;

            @include media-breakpoint-down('md') {
                max-width: 100%;
                flex: 0 0 100%;
                padding: 0 0 $table-cell-padding-sm !important;
            }

            &:not(.table-body-column-action) {
                > div {
                    width: 100%;
                    @include media-breakpoint-up('xl3') {
                        color: transparent;
                        position: relative;
                        overflow: hidden;
                        &:after {
                            content: attr(title);
                            color: $body-color;
                            position: absolute;
                            top: 50%;
                            left: 0;
                            right: 0;
                            @include text-truncate();
                            white-space: nowrap;
                            transform: translateY(-50%);
                        }
                    }

                }
            }

            &-id {
                text-align: center;

                @include media-breakpoint-down('md') {
                    max-width: 150px;
                    flex: 0 0 150px;
                    text-align: left;
                    padding-top: 7px;
                    padding-bottom: 20px;
                    font-size: $font-size-lg * .9;
                }

                label {
                    display: none;
                }
            }

            &-route_type {
                text-align: center;

                @include media-breakpoint-down('md') {
                    position: absolute;
                    right: 30px;
                    top: 22px;
                }

                svg {
                    fill: $body-color;
                    height: 20px;
                    width: auto;
                }

                label {
                    display: none;
                }

                > div {
                    &:after {
                        content: none !important;
                    }
                }
            }

            &-price,
            &-date {
                white-space: nowrap;
            }

            &-action {
                @include media-breakpoint-down('md') {
                    position: absolute;
                    right: 15px;
                    top: 15px;
                }
            }
        }

    }
}
</style>