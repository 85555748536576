<template>
        <Modal v-if="user"
            id="thank-you-modal"
               size="lg"
               :centered="true"
                :show="hasNewBooking"
               :staticBackdrop="true"
               @hideModal="clearHasNewBooking"
        >
            <div class="pt-6 px-4 px-md-6 text-center">
                <div class="mb-5">
                    <div class="modal-check">
                        <span class="modal-check-line modal-check-line-tip"></span>
                        <span class="modal-check-line modal-check-line-long"></span>

                        <div class="modal-check-fix"></div>
                    </div>
                </div>
                <h2 class="h1 font-weight-semibold position-relative mb-5">Thank You {{ user.first_name }}, <span
                    class="font-weight-light">for your booking</span></h2>
                <p class="mb-5"><small>Your shipping instruction has been received by the
                    Account Manager. We are currently working on assigning a driver and schedule a
                    collection appointment according to your expectations.</small>
                </p>
                <p><small>You will get an e-mail confirmation including the details of a
                    van allowed to pick-up & deliver the goods as well as estimated collection time
                    shortly.</small></p>
                <p class="mt-6 mb-4">
                    <img src="@/assets/img/logo_gepa.svg" alt="Gepa Logistics">
                </p>
            </div>
        </Modal>
</template>

<script>
import {mapGetters} from "vuex";
import Modal from "@/components/Modal";

export default {
    name: "ThankYouModal",
    components: {Modal},
    props: {
        show: {
            default: false,
            type: Boolean,
        }
    },
    created() {
        if(this.hasNewBooking){
            this.$store.dispatch('checkout/clearQuote');
        }
    },
    computed: {
        ...mapGetters({
            user: 'auth/user',
            hasNewBooking: 'checkout/hasNewBooking',
        }),
    },
    methods: {
        clearHasNewBooking(){
            this.$store.dispatch('checkout/hasNewBooking', false);
        }
    }
}
</script>

<style scoped lang="scss">

@keyframes rotate-circle {
    0% {
        transform: rotate(-45deg);
    }
    5% {
        transform: rotate(-45deg);
    }
    12% {
        transform: rotate(-405deg);
    }
    100% {
        transform: rotate(-405deg);
    }
}

@keyframes icon-line-tip {
    0% {
        width: 0;
        left: -7px;
        top: 18px;
    }
    54% {
        width: 0;
        left: -7px;
        top: 18px;
    }
    70% {
        width: 65px;
        left: -14px;
        top: 29px;
    }
    84% {
        width: 17px;
        left: 25px;
        top: 49px;
    }
    100% {
        width: 29px;
        left: 15px;
        top: 45px;
    }
}

@keyframes icon-line-long {
    0% {
        width: 0;
        right: 48px;
        top: 54px;
    }
    65% {
        width: 0;
        right: 38px;
        top: 54px;
    }
    84% {
        width: 55px;
        right: 2px;
        top: 36px;
    }
    100% {
        width: 47px;
        right: 8px;
        top: 38px;
    }
}


.modal {

    &-check {
        transform: scale(1.5);
        width: 80px;
        height: 80px;
        position: relative;
        border-radius: 50%;
        box-sizing: content-box;
        border: 12px solid $success;
        margin-left: auto;
        margin-right: auto;

        &:before, &:after {
            content: '';
            height: 105px;
            position: absolute;
            background: $white;
            transform: rotate(-45deg);
        }

        &:before {
            top: 2px;
            left: -9px;
            width: 35px;
            height: 105px;
            transform-origin: 100% 50%;
            border-radius: 105px 0 0 105px;

        }

        &:after {
            top: -16px;
            left: 32px;
            width: 65px;
            height: 125px;
            transform-origin: 0 50%;
            border-radius: 0 105px 105px 0;
            animation: rotate-circle 4.25s ease-in both;
            animation-delay: 0.4s;
        }

        &-line {
            height: 12px;
            background-color: $success;
            display: block;
            border-radius: 3px;
            position: absolute;
            z-index: 10;

            &-tip {
                transform: rotate(45deg);
                animation: icon-line-tip 0.75s both;
                animation-delay: 0.4s;
                width: 0;
                left: -7px;
                top: 18px;
            }

            &-long {
                transform: rotate(-45deg);
                animation: icon-line-long 0.75s both;
                animation-delay: 0.4s;
                width: 0;
                right: 48px;
                top: 54px;
            }
        }

        &-fix {
            width: 12px;
            top: -1px;
            left: 24px;
            z-index: 1;
            height: 105px;
            position: absolute;
            transform: rotate(-45deg);
            background-color: $white;
        }
    }
}
</style>