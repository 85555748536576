<template>
    <PrivateLayout v-if="loaded && user">
        <div
            class="d-flex flex-column flex-lg-row flex-column-reverse justify-content-between align-items-lg-center mt-5">
            <h1 class="font-weight-light mt-5 mt-lg-0">
                <strong class="font-weight-medium">Hi {{ user.first_name }},</strong>
                Welcome Back!
            </h1>
            <RouterLink :to="{name: 'new-quote'}"
                        id="app-new-quote-btn"
                        class="btn btn-primary btn-lg"
                        :style="{width: '200px'}">
                <small class="font-weight-medium">Get a new quote</small>
            </RouterLink>
        </div>
        <div class="card border-none mt-4">
            <div v-if="bookings.data.length" class="card-body py-lg-4 px-lg-5">
                <h4 class="text-primary font-weight-semibold my-3">Your last quotes</h4>

                <TableQuotes :bookings="bookings"
                        @sort="handleSubmit"/>
                <Pagination :current="form.page"
                            :max="bookings.meta.last_page"
                            @change="handleChangePagination"
                />

            </div>
            <div v-else class="card-body py-lg-4 px-lg-5">
                <div class="alert alert-info mb-0 py-5 text-center">
                    <h3 class="mb-5">You haven't made any bookings yet.</h3>
                    <p class="mb-0">
                        <RouterLink :to="{name: 'new-quote'}"
                                    class="btn btn-primary btn-lg"
                                    :style="{width: '200px'}">
                            <small class="font-weight-medium">Do it now!</small>
                        </RouterLink>
                    </p>
                </div>
            </div>
        </div>

        <CertifiedLogos class="mt-6 pt-5"/>
        <ThankYouModal/>

    </PrivateLayout>
</template>

<script>
import CertifiedLogos from "@/components/CertifiedLogos";
import ThankYouModal from "@/shared/dashboard/ThankYouModal";
import TableQuotes from "@/shared/dashboard/TableQuotes";
import {name} from "@/config/app";
import {mapGetters} from "vuex";
import Pagination from "@/components/Pagination";

export default {
    name: "Dashboard",
    middleware: 'auth',
    meta: {
        title: `Dashboard - ${name}`
    },
    data: () => ({
        loaded: false,
       bookings: null,
        form: {
            page: 1,
            order: 'desc',
            orderBy: 'id',
        }
    }),
    async created() {
        this.form.page = this.$route.params.page;
        this.bookings = await this.fetchBookings();
        this.form.page = this.bookings.meta.current_page;
        this.checkMaxPage(this.form.page);
        this.loaded = true;
    },

    components: {Pagination, TableQuotes, CertifiedLogos, ThankYouModal},
    computed: {
        ...mapGetters({
            'user': 'auth/user'
        }),
    },
    watch: {
        async $route() {
            this.checkMaxPage(this.$route.params.page)
            this.form.page = this.$route.params.page || 1;
            this.bookings = await this.fetchBookings();
        }
    },
    methods: {
        async fetchBookings() {
            return await this.$store.dispatch('user/fetchBookings', {
                ...this.form
            })
        },
        handleChangePagination(page) {
            const params = {
                page: page > 1 ? page : undefined
            };

            this.$router.push({name: 'dashboard', params});
        },
        checkMaxPage(page) {
            page = page !== undefined ? parseInt(page) : 1;
            if (page > this.bookings.meta.last_page) {
                this.$router.push({
                    name: 'dashboard',
                    params: {
                        ...this.$route.params,
                        ...{page: this.bookings.meta.last_page}
                    }
                });
            }
        },

        async handleSubmit(data){
            const {form} = this
            this.form = {
                ...form,
                ...data
            }

            this.$root.$preloader.start()
            this.bookings = await this.fetchBookings();
            this.$root.$preloader.finish();
        }
    }
}
</script>
<style lang="scss">


</style>